$( document ).ready(function() {

    $.each($('.from-now'), function(i,o) {
        var d = $(o).data('date');
        if(d != null && d != undefined) {
            $(o).html(moment(d).fromNow());
        }
    });

    $('.toggle-password').on('click', function(e) {
        e.preventDefault();

        if(  $(this).data('field') != undefined ) {
            var $field = $( $(this).data('field') );

            if( $field.length != 0 ) {

                if( $('i.fas', $(this)).hasClass('fa-eye') == true ) {
                    $('i.fas', $(this)).removeClass('fa-eye').addClass('fa-eye-slash');
                    $field.attr('type', 'text');
                } else if( $('i.fas', $(this)).hasClass('fa-eye-slash') == true ) {
                    $('i.fas', $(this)).removeClass('fa-eye-slash').addClass('fa-eye');
                    $field.attr('type', 'password');
                }
            }

        }

    });

    $('.toggle-class-link').on('click', function(e) {
      e.preventDefault();
      var $target = $($(this).data('target'));
      var css_class = $(this).data('class');
      $target.toggleClass(css_class);
    });

    $( '#home-sidebar' ).sliderPro({
        width: '100%',
        height: 730,
        arrows: true,
        buttons: false,
        waitForLayers: true,
        fade: true,
        autoplay: true,
        autoplayDelay: 6000,
        autoScaleLayers: false,
        touchSwipe: false,
        breakpoints: {
            360: {
                height: 200
            },
            768: {
                height: 300
            }
        }
    });

    if($('.sidebars').length != 0)
    {
        var sides = ['left', 'top', 'right', 'bottom'];

        for (var i = 0; i < sides.length; ++i) {
            var cSide = sides[i];
            $('.sidebar.' + cSide).sidebar({side: cSide});
        }

        $('.sidebar-btn[data-action]').on('click', function () {
            var $this = $(this);
            var action = $this.attr('data-action');
            var side = $this.attr('data-side');
            $('.sidebar.' + side).trigger('sidebar:' + action);
            return false;
        });
    }

    // Menu Fixed
    $(window).scroll(function(){
      var heightMenu = $('.Header').outerHeight(true);

      if($(this).scrollTop() > 0){
        $('.Header').addClass('fixed');
        $('.Banner').css('margin-top', (heightMenu) + 'px');
      } else {
        $('.Header').removeClass('fixed');
        $('.Banner').css('margin-top', '0');
      }
    });
});
